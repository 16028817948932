import * as React from 'react';
import {
    getAuthUser,
    logout
} from '../helpers/user';
import {
    Form, Formik
} from 'formik';
import {
    navigate
} from 'gatsby';
import {
    useEffect, useState
} from 'react';
import DefaultPage from '../components/layouts/DefaultPage';
import CustomButton from '../components/CustomButton/CustomButton';
import {
    connect
} from 'react-redux';
import Card from '../components/Card/Card';
import Confirmation from '../components/Confirmation/Confirmation';
import {
    destroyAccount
} from '../api/application';
import EmailInput from '../components/formElements/EmailInput/EmailInput';
import PasswordInput from '../components/formElements/PasswordInput/PasswordInput';


// markup
const ProfilePage = () => {
    const user = getAuthUser();
    const [confirmation, setConfirmation,] = useState(null);

    useEffect(
        () => {
            if (!user) {
                navigate('/');
            }
        },
        []
    );

    if (!user) {
        return null;
    }

    if (confirmation) {
        return confirmation;
    }

    return (
        <DefaultPage useBackButton={true}>
            <Card>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        password: '',
                        password_confirmed: '',
                    }}
                    validate={values => {
                        const errors = {} as IFormErrors;
                        if (!values.email) {
                            errors.email = 'Pflichtfeld';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Ungültige E-Mail Adresse';
                        }
                        return errors;
                    }}
                >
                    {({
                        // isSubmitting,
                        values,
                    }) => {
                        return (
                            <Form className="">
                                <div style={{
                                    display: 'none',
                                }}>
                                    <div className="grid-10--desktop flex">
                                        <div className="grid-5--desktop grid-10--tablet grid-10--mobile">
                                            <EmailInput
                                                label="E-Mail"
                                                value={values.email}
                                                name="email"
                                            />
                                        </div>
                                        <div className="grid-4--desktop grid-offset-1--desktop grid-10--tablet grid-10--mobile">
                                            <br />
                                            <CustomButton
                                                style="link"
                                                label="Editieren"
                                                onClick={() => {
                                                    alert();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="grid-10--desktop flex">
                                        <div className="grid-5--desktop grid-10--tablet grid-10--mobile">
                                            <PasswordInput
                                                label="Passwort"
                                                defaultValue={values.password}
                                                name="password_confirmed"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="grid-4--desktop grid-offset-1--desktop grid-10--tablet grid-10--mobile">
                                            <br />
                                            <CustomButton
                                                style="link"
                                                label="Editieren"
                                                onClick={() => {
                                                    alert();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="">
                                    <CustomButton
                                        label="Logout"
                                        onClick={logout}/>
                                    <br/>
                                    <br/>
                                    <CustomButton
                                        label="Bewerbung und Konto löschen"
                                        style="link"
                                        onClick={() => {
                                            setConfirmation(<Confirmation
                                                critical
                                                message="Möchten Sie Ihre Bewerbung und Ihr Konto jetzt unwiderruflich löschen?"
                                                confirm={async () => {
                                                    const result = await destroyAccount();
                                                    if (result.success) {
                                                        logout();
                                                    }
                                                }}
                                                confirmLabel="Bewerbung und Konto jetzt unwiderruflich löschen"
                                                dismiss={() => {
                                                    setConfirmation(null);
                                                }}
                                                dismissLabel="Abbrechen"
                                            />);
                                        }}/>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Card>
        </DefaultPage>
    );
};

const mapStateToProps = ({
    authenticatedUser,
}) => {
    return {
        authenticatedUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);

