import * as React from 'react';

// @ts-ignore
import * as styles from './Card.module.css';

interface ICard {
    children?: React.ReactElement | React.ReactElement[],
    className?: string
}

const Card = ({
    children,
    className,
}: ICard) => {
    return <div className={`${styles.container} ${className} grid-2--mobile grid-4--tablet grid-6--desktop`}>
        {
            children
        }
    </div>;
};

export default Card;
