import * as React from 'react';

// @ts-ignore
import * as styles from './Confirmation.module.css';
import CustomButton from '../CustomButton/CustomButton';

interface IAlert {
    critical?: boolean,
    message: string
    dismissLabel: string
    dismiss: () => void
    confirmLabel: string
    confirm: () => void
}

const Confirmation = ({
    message,
    dismissLabel,
    dismiss,
    confirmLabel,
    confirm,
    critical,
}: IAlert) => {
    return <>
        <div className={`${styles.background} ${critical ? styles.critical : ''}`}/>
        <div className={styles.container}>
            <h4>{message}</h4>
            <div className={styles.buttonContainer}>
                <CustomButton
                    label={dismissLabel}
                    onClick={dismiss}
                />
                <CustomButton
                    label={confirmLabel}
                    onClick={confirm}
                />
            </div>
        </div>
    </>;
};

export default Confirmation;
